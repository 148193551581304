import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import PublicFooter from '@/partials/PublicFooter/PublicFooter.vue'
import { DASHBOARD } from '@/constants/routes'
import { exchangeSocialTemporaryToken } from '@/api/usersAndProfiles/auth'
import Benefits from './Benefits/Benefits.vue'
import Blog from './Blog/Blog.vue'
import Faq from './Faq/Faq.vue'
import FeaturedIn from './FeaturedIn/FeaturedIn.vue'
import FindTalent from './FindTalent/FindTalent.vue'
import GetPremium from './GetPremium/GetPremium.vue'
import LaborxPlatform from './LaborxPlatform/LaborxPlatform.vue'
import NewCryptoJobs from './NewCryptoJobs/NewCryptoJobs.vue'
import NewGigs from './NewGigs/NewGigs.vue'
import PublicHeader from './PublicHeaderNew/PublicHeader.vue'
import GetWork from './GetWork/GetWork.vue'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  components: {
    Benefits,
    Blog,
    Faq,
    FeaturedIn,
    FindTalent,
    GetPremium,
    GetWork,
    LaborxPlatform,
    NewCryptoJobs,
    NewGigs,
    PublicFooter,
    PublicHeader,
  },
  data () {
    return {
      preloading: false,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    }),
    ...mapState('landingCopy', {
      gigsCount: (state: any) => state.gigsCount,
      jobsCount: (state: any) => state.jobsCount,
    }),
    ...mapGetters({
      posts: 'blog/lastPosts',
      jobs: 'landingCopy/jobListPublic',
      gigs: 'landingCopy/gigListPublic',
    }),
  },
  async prefetch () {
    if (process.server) {
      await this.getLandingData()
    }
  },
  async created () {
    if (process.client) {
      if (!this.posts.length || !this.gigsCount || !this.jobsCount) {
        try {
          this.preloading = true
          await this.getLandingData()
        } finally {
          this.preloading = false
        }
      }
    }
  },
  async mounted () {
    if (this.$route.query.action && this.$route.query.action === 'reset') {
      this.openModal({
        component: 'lx-reset-modal',
        props: {
          token: this.$route.query.token
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.action === '2fa') {
      const token = this.$route.query.token
      this.openModal({
        component: 'lx-confirm-2fa-modal',
        props: {
          confirm: async (key: string) => {
            const newToken = await exchangeSocialTemporaryToken(token, key)
            this.setToken(newToken.token)
            this.$router.push({ name: DASHBOARD })
          }
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.token) {
      this.openModal({
        component: 'lx-sign-up-social-modal',
        props: {
          token: this.$route.query.token,
          authClient: this.$route.query.authclient,
          viaLogin: !!Number(this.$route.query.module),
        }
      })
      this.$router.replace({ query: null })
    } else if (this.$route.query.error) {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Error',
          text: this.$route.query.error
        }
      })
      this.$router.replace({ query: null })
    } else if ((this.$route.query || {}).hasOwnProperty('sign-up') && !this.isLoggedIn) {
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          fromType: 'fromType',
        }
      })
    }
    if (this.$route.path.includes('main')) {
      this.setUtmMetrics({ utm_campaign: 'tlx' })
    }
  },
  methods: {
    ...mapMutations({
      setUtmMetrics: 'user/setUtmMetrics',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      setToken: 'app/setToken',
      getLandingData: 'landingCopy/getLandingData',
    }),
  },
  metaInfo: {
    title: 'Crypto Jobs: WEB3 & Blockchain Jobs - Crypto Freelance',
    titleTemplate: '%s',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex',
      },
      {
        vmid: 'description',
        name: 'description',
        // eslint-disable-next-line max-len
        content: 'LaborX is the biggest Crypto and WEB3 Jobs freelance platform. The best way to find Blockchain and Crypto Jobs that pay in cryptocurrency. Hire expert freelancers and pay for projects with BTC, ETH, USDT, USDC and others.'
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Crypto Jobs: WEB3 & Blockchain Jobs - Crypto Freelance',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        // eslint-disable-next-line max-len
        content: 'LaborX is the biggest Crypto and WEB3 Jobs freelance platform. The best way to find Blockchain and Crypto Jobs that pay in cryptocurrency. Hire expert freelancers and pay for projects with BTC, ETH, USDT, USDC and others.'
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/preview/preview.png`
      },
      {
        vmid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        vmid: 'twitter:image',
        property: 'twitter:src',
        content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/preview/landing/twitter.png`
      },
    ]
  },
})
