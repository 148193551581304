import { render, staticRenderFns } from "./GetWork.htm?vue&type=template&id=65d0e906&scoped=true&"
import script from "./GetWork.ts?vue&type=script&lang=js&"
export * from "./GetWork.ts?vue&type=script&lang=js&"
import style0 from "./GetWork.scss?vue&type=style&index=0&id=65d0e906&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d0e906",
  null
  
)

export default component.exports