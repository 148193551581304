import { PREMIUM } from '@/constants/routes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default {
  data () {
    return {
      PREMIUM,
    }
  },
  methods: {
    onMoreClick () {
      googleAnalyticsV2.send({ event: 'new-lp-1-premium-click' })
    },
  }
}
