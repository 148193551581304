import Vue from 'vue'
import { POST_A_JOB, SERVICES } from '@/constants/routes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default Vue.extend({
  data () {
    return {
      POST_A_JOB,
      SERVICES,
    }
  },
  methods: {
    onPostClick () {
      googleAnalyticsV2.send({ event: 'new-lp-1-post-a-job-click' })
    },
    onWorkClick () {
      googleAnalyticsV2.send({ event: 'new-lp-1-how-does-it-work-click' })
    },
  }
})
