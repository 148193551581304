// @ts-ignore
import NoSsr from 'vue-no-ssr'
import Vue from 'vue'
import throttle from 'lodash/throttle'
import { mapActions, mapState } from 'vuex'
import { ABOUT_US, DASHBOARD, BLOG, BROWSE_JOBS, FREELANCERS_LIST, SERVICES, VACANCIES } from '@/constants/routes'
import { disablePageScroll, enablePageScroll } from '@/utils/scrollLock'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { AppState } from '@/store/shared/modules/app/types'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  components: {
    NoSsr,
  },
  data () {
    return {
      menuOpen: false,
      isLoading: false,
      ABOUT_US,
      DASHBOARD,
      FREELANCERS_LIST,
      BLOG,
      BROWSE_JOBS,
      SERVICES,
      VACANCIES,
      pageLoaded: false,
      scrolled: false,
    }
  },
  computed: {
    ...mapState<RootState>('app', {
      isLoggedIn: (state: AppState) => state.authorized,
    }),
    isLoggedInLazy () { return this.pageLoaded && this.isLoggedIn },
    scrollThreshold () {
      return this.xsAndDown
        ? 10
        : 24
    },
  },
  watch: {
    isTabletLx () {
      if (!this.isTabletLx) {
        this.menuOpen = false
      }
    },
    menuOpen () {
      if (this.menuOpen) {
        this.$nextTick(() => {
          disablePageScroll()
        })
      } else {
        enablePageScroll()
      }
    },
  },
  mounted () {
    this.pageLoaded = true
    if (this.$route.query.redirect) {
      this.openLogin()
    }
    window.addEventListener('scroll', this.onScrollPage)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScrollPage)
    enablePageScroll()
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      logout: 'app/logout',
    }),
    closeMenu () {
      this.menuOpen = false
    },
    openLogin () {
      this.closeMenu()
      this.openModal({
        component: 'lx-login-modal',
        id: 'lx-login-modal',
        props: {
          fromType: 'landing-copy',
        }
      })
      googleAnalyticsV2.send({
        'event': 'new-lp-1-login-button-click',
      })
    },
    openSignUp () {
      this.closeMenu()
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          fromType: 'landing-copy',
        }
      })
      googleAnalyticsV2.send({
        'event': 'new-lp-1-register-button-click'
      })
    },
    async onLogout () {
      this.isLoading = true
      this.closeMenu()
      await this.logout().catch((e: any) => console.error(e))
      this.isLoading = false
    },
    onLinkClick (event: string) {
      googleAnalyticsV2.send({ event })
      this.closeMenu()
    },
    onClickMenu () {
      this.menuOpen = !this.menuOpen
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    },
    onScrollPage: throttle(function (this: any) {
      this.scrolled = window.scrollY >= this.scrollThreshold
    }, 30),
  }
})
