import Vue from 'vue'
import LandingJob from '@/models-ts/landing/LandingJob'
import {
  BROWSE_VACANCIES_BY_SKILL,
  CUSTOMER_PROFILE,
  VACANCY_DETAILS
} from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import LandingVacancy from '@/models-ts/landing/LandingVacancy'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyLocationTooltip,
  },
  props: {
    vacancy: LandingVacancy,
  },
  data () {
    return {
      CUSTOMER_PROFILE,
      VACANCY_DETAILS,
    }
  },
  computed: {
    salary () {
      if (!this.vacancy.salary_from) return
      if (this.vacancy.salary_type === SalaryTypes.FIXED) {
        return formatUsd(this.vacancy.salary_from)
      }
      return `${formatUsd(this.vacancy.salary_from)}-${formatUsd(this.vacancy.salary_to)}`
    }
  },
  methods: {
    getSkillTags (job: LandingJob) {
      return job.skills
        .map(skill => ({
          text: skill.name,
          ...(!skill.is_custom && skill.url && { link: { name: BROWSE_VACANCIES_BY_SKILL, params: { skill: skill.url } } })
        }))
        .slice(0, 2)
    },
    onLinkClick () {
      googleAnalyticsV2.send({ event: 'new-lp-1-ftj-body' })
    },
  },
})
